

































































































































































import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { SavingsServiceType } from '@/vue-app/types';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsPersonalInformationAddressViewModel
  from '@/vue-app/view-models/components/contract-savings/personal-information/contract-savings-personal-information-address-view-model';

const SERVICES = {
  allianz: 'AddressAllianz',
  kuspit: 'AddressKuspit',
};

@Component({
  name: 'ContractSavingsPersonalInformationAddress',
  components: {
    AddressKuspit: () => import('./personal-information/address/AddressKuspit.vue'),
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class ContractSavingsPersonalInformationAddress extends Vue {
  @Prop(String)
  readonly service!: SavingsServiceType;

  personal_information_view_model
    = Vue.observable(new ContractSavingsPersonalInformationAddressViewModel());

  current_component = SERVICES[this.service];

  @Watch('personal_information_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  @Watch('personal_information_view_model.address_proof')
  onFileChange(file: File) {
    if (file) {
      this.personal_information_view_model.setFileData(file);
    }
  }

  @Watch('personal_information_view_model.full_address_on_ine')
  onFullAddressOnIneChange(full_address_on_ine: string) {
    this.personal_information_view_model.payload.complete_and_correct_address = full_address_on_ine === 'yes';
    if (this.personal_information_view_model.payload.complete_and_correct_address) {
      this.personal_information_view_model.address_proof = null;
      this.personal_information_view_model.payload.file = {
        ...this.personal_information_view_model.payload.file,
        name: '',
        mime_type: '',
        file_data: '',
        file_weight: '',
      };
    }
  }

  confirmAddress(confirmation: boolean) {
    this.personal_information_view_model.confirm_address = confirmation;
  }

  prevStep() {
    if (
      this.personal_information_view_model.small_screen
      || this.personal_information_view_model.internal_step === 1
    ) {
      this.$emit('prevStep');
    } else {
      this.personal_information_view_model.internal_step = 1;
    }
  }

  async nextStep() {
    if (!this.$vuetify.breakpoint.smAndDown) {
      if (this.personal_information_view_model.internal_step === 1) {
        const save_step = await this.personal_information_view_model.saveStep();
        if (save_step) {
          this.personal_information_view_model.internal_step = 2;
        }
      } else {
        const access_to_form = this.$refs.address_form as any;
        const saved = await access_to_form.saveStep();
        if (saved) {
          this.$emit('nextStep');
        }
      }
    } else {
      const save_step = await this.personal_information_view_model.saveStep();
      if (save_step) {
        const access_to_form = this.$refs.address_form as any;
        const saved = await access_to_form.saveStep();
        if (saved) {
          this.$emit('nextStep');
        }
      }
    }
  }

  async created() {
    await this.personal_information_view_model.initialize(getScreenSizeVariant(), this.service);
    this.$emit('loadingInfo', this.personal_information_view_model.is_loading);
  }
}

